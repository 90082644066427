export const userToken = useLocalStorage<string | null>('token', null)
export const userAddress = useLocalStorage<string | null>('address', null)

export function useUserInfo() {
  const { data: user } = useQuery(useUserInfoQueryOptions())
  return user
}

export function useLogoutMutation() {
  const router = useRouter()

  return {
    mutate({ replaceRoute = true }: { replaceRoute?: boolean } = {}) {
      userToken.value = null
      userAddress.value = null
      if (replaceRoute)
        router.replace('/login')
    },
  }
}
